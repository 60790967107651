// Config object to be passed to Msal on creation

export const msalConfig = {
    auth: {
        clientId: window._env_.REACT_APP_AAD_CLIENT_ID,
        authority: window._env_.REACT_APP_AAD_AUTHORITY,
        redirectUri: window._env_.REACT_APP_AAD_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};



// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

